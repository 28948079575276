import { FlatTreeControl } from '@angular/cdk/tree';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { AuthFacade } from '@shared/auth-module/store/auth.facade';
import { BusinessUnitsNavigationDataSource } from '@shared/business-structure/helpers/business-units-navigation.datasource';
import { BusinessUnitNode } from '@shared/business-structure/models/business-unit-node.model';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import { Client } from '@shared/clients/models/client.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { GovernanceService } from '@shared/governance/services/governance.service';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { User } from '@shared/users-module/models/user.model';
import { UserRolesEnum } from '@shared/users-module/types/user-roles.enum';
import { Subscription, combineLatestWith, filter, takeWhile } from 'rxjs';

@Component({
  selector: 'business-unit-navigation',
  templateUrl: './business-unit-navigation.component.html',
  styleUrls: ['./business-unit-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessUnitNavigationComponent implements OnInit {
  private __destroyRef = inject(DestroyRef);

  protected _isDisabled = false;
  businessPath: string[] = ['Argos Sainsburys', 'Argos', 'Food To Order'];
  treeControl: FlatTreeControl<BusinessUnitNode>;
  dataSource: BusinessUnitsNavigationDataSource;
  currentUser: User;
  clients: Client[] = [];
  path: BusinessUnit[] = [];
  currentClient: Client;
  currentBusinessUnit!: BusinessUnit;
  @ViewChild('trigger') triggerRef: ElementRef;
  @ViewChild('tree', { read: ElementRef }) treeRef: ElementRef;
  showTree = false;
  private __buNodeSub: Subscription;

  constructor(
    private readonly __clientFacade: ClientFacade,
    private readonly __authFacade: AuthFacade,
    private readonly __cd: ChangeDetectorRef,
    private readonly __router: Router,
    private readonly __governanceService: GovernanceService,
    private readonly __businessStructureFacade: BusinessStructureFacade,
    private readonly __location: Location,
    private readonly __loaderService: GlobalLoaderService
  ) {}

  @HostBinding('class')
  get hostClass(): string {
    const cls: string[] = [];

    if (!this.clients?.length) {
      cls.push('d-none');
    }

    return cls.join(' ');
  }

  @HostListener('document:click', ['$event.target'])
  onGlobalClick(targetElement: HTMLElement): void {
    if (!this.showTree) {
      this.showTree = targetElement === this.triggerRef.nativeElement || this.triggerRef.nativeElement.contains(targetElement);
    } else {
      this.showTree = targetElement === this.treeRef.nativeElement || this.treeRef.nativeElement.contains(targetElement);
    }
  }

  ngOnInit(): void {
    this.treeControl = new FlatTreeControl<BusinessUnitNode>(this.getLevel, this.isExpandable);
    this.dataSource = new BusinessUnitsNavigationDataSource(this.__destroyRef, this.treeControl, this.__businessStructureFacade);

    this.__governanceService.disableBusinessStructureNavigation$.subscribe((event) => {
      this._isDisabled = event;
      this.__cd.detectChanges();
    });

    this.__clientFacade.selectedClient$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        filter((client) => !!client)
      )
      .subscribe((client) => (this.currentClient = client));

    this.__authFacade.user$
      .pipe(
        takeUntilDestroyed(this.__destroyRef),
        combineLatestWith(this.__clientFacade.clients$, this.__clientFacade.selectedClient$, this.__clientFacade.selectedBusinessUnit$)
      )
      .subscribe(([currentUser, clients, currentClient, currentBusinessUnit]) => {
        this.__loaderService.dismiss('selected-client-change');

        this.clients = clients.list?.filter((client) => client.role !== UserRolesEnum.ANY || client.is_requested);
        this.currentUser = currentUser;
        this.currentClient = currentClient;
        this.currentBusinessUnit = currentBusinessUnit;
        this.dataSource.data = this.__initialData();

        this.__cd.markForCheck();

        setTimeout(() => {
          if (this.__clientFacade.selectedBusinessUnit) {
            const rootNode = this.treeControl.dataNodes
              .filter((node) => node.entity instanceof Client)
              .find((node) => {
                const client = node.entity as Client;
                return client?.slug === this.currentClient?.slug;
              });
            if (rootNode && !rootNode.expanded) {
              this.__chooseSelectedNode();
              this.treeControl.expand(rootNode);
            }
          } else {
            this.path = [];
          }
          this.__cd.detectChanges();
        });
      });

    this.__businessStructureFacade.businessStructureSelection$
      .pipe(takeUntilDestroyed(this.__destroyRef))
      .subscribe(() => this.__chooseSelectedNode());
  }

  private __chooseSelectedNode(): void {
    this.__buNodeSub && this.__buNodeSub.unsubscribe();

    this.__buNodeSub = this.dataSource.dataChange.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((nodes) => {
      const buNode = nodes.find((node) => node.entity instanceof BusinessUnit && node.entity.id === this.currentBusinessUnit?.id);
      if (buNode) {
        this.__buNodeSub.unsubscribe();
        this.path = this.__getPath(buNode);
        this.__cd.markForCheck();
      }
    });
  }

  private __initialData(): BusinessUnitNode[] {
    return this.clients.map(
      (client) =>
        new BusinessUnitNode(
          client,
          client.slug,
          client.name,
          null,
          0,
          client.has_children,
          false,
          this.__clientFacade.hasGlobalAccess(client),
          false
        )
    );
  }

  getLevel = (node: BusinessUnitNode): number => node.level;

  isExpandable = (node: BusinessUnitNode): boolean => node.expandable;

  hasChild = (_: number, _nodeData: BusinessUnitNode): boolean => _nodeData.expandable;

  private __getRootParentNode(node: BusinessUnitNode): BusinessUnitNode {
    return node.parentNode ? this.__getRootParentNode(node.parentNode) : node;
  }

  private __getRootParentClient(node: BusinessUnitNode): Client {
    let client: Client;
    const parentNode = this.__getRootParentNode(node.parentNode);

    if (parentNode) {
      client = parentNode.entity as Client;
    }

    return client;
  }

  private __getPath(node: BusinessUnitNode): BusinessUnit[] {
    const path: BusinessUnit[] = [];

    if (node.entity instanceof BusinessUnit) {
      path.unshift(node.entity);

      if (node.parentNode) {
        path.unshift(...this.__getPath(node.parentNode));
      }
    }

    return path;
  }

  selectBu(node: BusinessUnitNode): void {
    if (!node) {
      return;
    }

    let client: Client = null;
    let businessUnit: BusinessUnit = null;

    if (node.level === 0) {
      client = node.entity as Client;
      this.path = [];
    } else {
      client = this.__getRootParentClient(node);
      businessUnit = node.entity as BusinessUnit;
      this.path = this.__getPath(node);
    }

    if (this.__router.url.includes(APP_ROUTES.part.business_structure)) {
      if (client?.id === this?.currentClient?.id) {
        this.showTree = false;
        return;
      }
      this.__navigateAfterClientSelection(client, businessUnit);
    } else {
      let unsubscribe = false;
      this.__clientFacade.clientAndBusinessUnit$.pipe(takeWhile(() => !unsubscribe)).subscribe(([selectedClient]) => {
        if (selectedClient) {
          unsubscribe = true;
        }
        this.__navigateAfterClientSelection(client, businessUnit);
      });
      this.__loaderService.create('selected-client-change');
      this.__clientFacade.setSelectedClientAndBusinessUnit(client, businessUnit);
    }

    this.showTree = false;
  }

  private __navigateAfterClientSelection(client: Client, businessUnit: BusinessUnit): void {
    const previousClientSlug = this.currentClient?.slug;

    if (client) {
      if (this.__router.url.includes(`${APP_ROUTES.part.admin}/${APP_ROUTES.part.clients}`)) {
        this.__router.navigate(APP_ROUTES.accountsV1.overview(client?.slug));
      } else if (previousClientSlug && previousClientSlug !== client?.slug) {
        const url = this.__location.path().replace(`/${previousClientSlug}/`, `/${client.slug}/`);
        this.__router.navigateByUrl(url, { state: { client, businessUnit } });
      }
    }
  }
}
