<div mat-buttons-group class="buttons left">
  <button mat-raised-button mat-icon-only matTooltip="Zoom in" (click)="zoomIn(100)">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-raised-button mat-icon-only matTooltip="Zoom out" (click)="zoomOut(100)">
    <mat-icon>remove</mat-icon>
  </button>
  <span mat-buttons-group-separator></span>
  <button
    mat-raised-button
    color="primary"
    mat-icon-only
    matTooltip="Fit content"
    [ngClass]="{ 'rounded-end-4': !(_mode === 'manager' && selectedClient.hasPrivilege('bs_edit')) }"
    (click)="fitToView()"
  >
    <mat-icon>fit_screen</mat-icon>
  </button>
  <button mat-button class="rounded-start-4" *ngIf="_mode !== 'manager'" matTooltip="More info" (click)="tutorialPopup.show()">
    <span><b>How to connect your accounts?</b></span>
    <mat-icon class="outlined">info</mat-icon>
  </button>
  <ng-container *ngIf="_mode === 'manager' && selectedClient.hasPrivilege('bs_edit')">
    <span mat-buttons-group-separator></span>
    <button
      mat-raised-button
      color="primary"
      mat-icon-only
      matTooltip="Undo"
      (click)="historyUndo()"
      [disabled]="!_isEditNode || _historyProps.currentStep === 0"
    >
      <mat-icon>undo</mat-icon>
    </button>
    <button
      color="primary"
      mat-raised-button
      mat-icon-only
      matTooltip="Redo"
      (click)="historyRedo()"
      [disabled]="!_isEditNode || _historyProps.currentStep === _historyProps.steps.length - 1"
    >
      <mat-icon>redo</mat-icon>
    </button>
  </ng-container>
</div>

<div mat-buttons-group class="buttons right" *ngIf="_mode === 'manager' && selectedClient.hasPrivilege('bs_edit')">
  <button mat-raised-button mat-icon-only *ngIf="!isEdit" (click)="startEdit()" matTooltip="Edit business structure">
    <mat-icon class="outlined">edit</mat-icon>
  </button>
  <button
    mat-raised-button
    color="primary"
    mat-icon-only
    *ngIf="isEdit"
    (click)="cancelEdit()"
    matTooltip="Cancel edit"
    aria-label="cancel edit"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button mat-icon-only color="primary" *ngIf="isEdit" (click)="save()" [disabled]="!isChanged" matTooltip="Save changes">
    <mat-icon>save</mat-icon>
  </button>
</div>
<div #treeDragItems class="tree-items drag-item py-4" [ngClass]="{ 'is-dragged': hasTreeDragItem }">
  <ng-container *ngFor="let item of _dragTreeItems; let i = index">
    <div class="px-16 d-flex align-items-center" *ngIf="i < 1">
      <mat-checkbox (click)="$event.preventDefault()" [checked]="true" color="primary"></mat-checkbox>
      <div class="drag-item-label">{{ item.item.name }}</div>
    </div>
    <div class="drag-item-label px-16 py-4" *ngIf="i === 1">...and {{ _dragTreeItems.length - 1 }} more</div>
  </ng-container>
</div>

<div class="tree d-flex flex-column justify-items-start align-items-stretch" *ngIf="_mode === 'mapping'">
  <div class="tree__select-all d-flex flex-row justify-content-between align-items-center">
    <mat-checkbox
      [checked]="_treeAllSelected"
      color="primary"
      [indeterminate]="_treeSomeSelected"
      (change)="_treeMarkSelected($event.checked)"
      >Select all</mat-checkbox
    >
    <span class="flex-spacer"></span>
    <button type="button" mat-stroked-button color="primary" matTooltip="Refresh" matTooltipPosition="below" (click)="getAccounts.emit()">
      Refresh list
    </button>
  </div>
  <div class="tree__viewport" #treeViewport>
    <cdk-virtual-scroll-viewport itemSize="32">
      <bsm-tree-group [node]="node" *cdkVirtualFor="let node of _treeRender" [ngStyle]="{ height: '32px' }"></bsm-tree-group>
      <div class="tree-row additional-info" *ngIf="!_tree.length">
        <ng-container> No {{ treeItemsType }}s were found </ng-container>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<div class="viewport user-select-none bg-color-background--background border-1 border-color-neutral-variant--300 rounded-4" #viewport>
  <div class="node-items drag-item" [ngClass]="{ 'is-dragged': !!_dragMappingNodeItem }">
    <div class="inner d-flex flex-column align-items-center mt-12">
      <div class="accounts">
        <ng-container *ngIf="treeItemsType === 'account' && _dragMappingNodeItem?.item.accounts?.length">
          <span class="d-inline-block px-16 py-8"> {{ _dragMappingNodeItem.draggedDisplayName }} </span>
        </ng-container>
      </div>
    </div>
  </div>

  <bsm-node-item
    class="drag-item"
    [ngClass]="{ 'is-dragged': !!_dragNodeItem }"
    placeholder
    [item]="_dragNodeItem?.item"
    [levelNo]="_dragNodeItem?.item?.levelNo"
  ></bsm-node-item>

  <div class="viewscroll" #viewscroll>
    <div class="bs-row">
      <ng-container
        [ngTemplateOutlet]="bsRow"
        [ngTemplateOutletContext]="{ item, levelNo: _levelNo, isEdit: _isEditNode, parent: null }"
        *ngFor="let item of _data"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template let-item="item" let-parent="parent" let-levelNo="levelNo" let-isEdit="isEdit" let-index="index" #bsRow>
  <div class="bs-col" *ngIf="levelNo <= allowedLevelsDepth">
    <bsm-node-item
      #node
      [item]="item"
      [allowedLevelsDepth]="allowedLevelsDepth"
      [levelNo]="levelNo"
      [isEdit]="isEdit"
      (structureChange)="_hasChanged($event)"
      [parent]="parent"
      (dragStart)="_dragNodeStart($event)"
      (dragStop)="_dragNodeStop($event)"
      (dragItemStart)="_dragItemStart($event)"
      (dragItemStop)="_dragItemStop($event)"
      (accountsListViewToggle)="_nodeAccountsListViewToggle()"
      [attr.data-cy]="item.name"
      [attr.data-cy-parent]="parent?.item.name"
    ></bsm-node-item>
    <div class="bs-row" *ngIf="item?.children?.length">
      <ng-container
        *ngFor="let child of item.children"
        [ngTemplateOutlet]="bsRow"
        [ngTemplateOutletContext]="{ item: child, levelNo: levelNo + 1, isEdit, parent: node }"
      ></ng-container>
    </div>
  </div>
</ng-template>

<popup [title]="title" [content]="content" #tutorialPopup [popupoptions]="{ hideOnOverlayClick: true }" close></popup>
<ng-template #title>To connect your accounts..</ng-template>
<ng-template #content>
  <div class="mb-24">...drag & drop them from the Accounts list (left-hand side) to one of the Business Units (right-hand side)</div>
  <img src="assets/accounts-connect/tutorial.gif" height="500" />
</ng-template>
