import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';

export class BusinessStructureHelper {
  static extractAccessibleTree(children: BusinessUnit[]): BusinessUnit[] {
    const filtered: BusinessUnit[] = [];

    children &&
      children.forEach((child) => {
        child.divisions = BusinessStructureHelper.extractAccessibleTree(child.divisions);
        (child.is_active || child.divisions.length) && filtered.push(child);
      });

    return filtered;
  }

  static getAccessibleBusinessUnits(children: BusinessUnit[]): number[] {
    const filtered: number[] = [];

    children &&
      children.forEach((child) => {
        child.is_active && filtered.push(child.id);

        filtered.push(...BusinessStructureHelper.getAccessibleBusinessUnits(child.divisions));
      });

    return filtered;
  }
}
