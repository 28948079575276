import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BusinessStructureHelper } from '@shared/business-structure/helpers/business-structure.helper';
import { BusinessUnitNode } from '@shared/business-structure/models/business-unit-node.model';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import { Client } from '@shared/clients/models/client.model';
import { instanceToInstance } from 'class-transformer';
import { BehaviorSubject, Observable, map, merge } from 'rxjs';

export class BusinessUnitsNavigationDataSource implements DataSource<BusinessUnitNode> {
  dataChange = new BehaviorSubject<BusinessUnitNode[]>([]);
  private __node: BusinessUnitNode;
  private __expand: boolean;

  constructor(
    private __destroyRef: DestroyRef,
    private __treeControl: FlatTreeControl<BusinessUnitNode>,
    private __businessStructureFacade: BusinessStructureFacade
  ) {
    this.__businessStructureFacade.businessStructure$.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((d) => {
      if (this.__node) {
        const clientSlug = (this.__node.entity as Client).slug;
        const children = this.__businessStructureFacade.businessStructure?.data
          ? this.__businessStructureFacade.businessStructure?.data[clientSlug]?.tree
          : [];

        this.addNode(this.__node, this.__expand, children, this.__node.level + 1);
      }
    });
  }

  get data(): BusinessUnitNode[] {
    return this.dataChange.value;
  }

  set data(value: BusinessUnitNode[]) {
    this.__treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<BusinessUnitNode[]> {
    this.__treeControl.expansionModel.changed.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((change) => {
      if ((change as SelectionChange<BusinessUnitNode>).added || (change as SelectionChange<BusinessUnitNode>).removed) {
        this.handleTreeControl(change as SelectionChange<BusinessUnitNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(_collectionViewer: CollectionViewer): void {}

  handleTreeControl(change: SelectionChange<BusinessUnitNode>): void {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  toggleNode(node: BusinessUnitNode, expand: boolean): void {
    this.__node = node;
    this.__expand = expand;

    const client = node.entity as Client;
    if (node.level === 0 && client.slug) {
      node.isLoading = expand && true;
      if (this.__businessStructureFacade.businessStructure?.data && client.slug in this.__businessStructureFacade.businessStructure.data) {
        setTimeout(() => {
          this.addNode(node, expand, this.__businessStructureFacade.businessStructure.data[client.slug].tree, node.level + 1);
        });
      } else {
        this.__businessStructureFacade.getBusinessStructure(client);
      }
    } else {
      const children = (node.entity as BusinessUnit).divisions;
      if (children) {
        this.addNode(node, expand, children, node.level + 1);
      }
    }
  }

  addNode(node: BusinessUnitNode, expand: boolean, children: BusinessUnit[], level: number): void {
    const index = this.data.indexOf(node);
    if (!children || index < 0) {
      return;
    }

    if (expand) {
      const filteredChildren = BusinessStructureHelper.extractAccessibleTree(instanceToInstance(children));
      const nodes = filteredChildren.map(
        (bu: BusinessUnit) =>
          new BusinessUnitNode(bu, node.clientSlug, bu.name, node, level, !!bu.divisions.length, false, bu.is_active, false)
      );

      this.data.splice(index + 1, 0, ...nodes);
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
      this.data.splice(index + 1, count);
    }

    node.isLoading = false;
    node.expanded = expand;

    this.data = [...this.data];

    if (expand && node.level === 0) {
      this.__expandToSelectedBusinessUnit(node);
    }
  }

  private __expandToSelectedBusinessUnit(node: BusinessUnitNode): void {
    const subNodes = this.getSubNodes(node);
    subNodes.map((subNode) => {
      if (subNode.expandable) {
        this.__treeControl.expand(subNode);

        this.__expandToSelectedBusinessUnit(subNode);
      }
    });
  }

  private getSubNodes(node: BusinessUnitNode): BusinessUnitNode[] {
    return this.__treeControl.dataNodes.filter((el) => el.level === node.level + 1 && el.parentNode?.item === node.item);
  }
}
