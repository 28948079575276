import { BsTreeItem } from '@shared/business-structure/models/bs-tree-item.model';

export class BsTreeItemNode<T = any> {
  treeId: string | number;
  parentTreeId: string | number;
  depth: number;
  expanded?: boolean;
  reset_expansion?: boolean;
  has_children: boolean;
  item: BsTreeItem<T>;
}
