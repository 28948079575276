import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BusinessStructureComponent } from '@modules/client/pages/business-structure/business-structure.component';
import { LeaveBusinessStructurePopupComponent } from '@shared/business-structure/components/leave-business-structure-popup/leave-business-structure-popup.component';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { Observable, map, of, tap } from 'rxjs';

@Injectable()
export class LeaveBusinessStructureGuard implements CanDeactivate<unknown> {
  constructor(
    private readonly __matDialog: MatDialog,
    private __router: Router,
    private __clientFacade: ClientFacade,
    private __loaderService: GlobalLoaderService
  ) {}

  canDeactivate(
    _component: BusinessStructureComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (_component.$businessStructureManagerComponent()?.isChanged) {
      return this.__matDialog
        .open(LeaveBusinessStructurePopupComponent)
        .afterClosed()
        .pipe(
          map((res) => !!res),
          tap((res) => this.handleNavigation(res))
        );
    }
    return of(true).pipe(tap((res) => this.handleNavigation(res)));
  }

  private handleNavigation(navigationResult: boolean): void {
    const routerState = this.__router.getCurrentNavigation().extras.state;
    if (!routerState) {
      return;
    }
    const { client, businessUnit } = routerState;
    if (navigationResult) {
      this.__clientFacade.setSelectedClient(client);
      this.__clientFacade.setSelectedBusinessUnit(businessUnit);
      this.__loaderService.create('selected-client-change');
    }
  }
}
