export class ColorHelper {
  static hex2rgb(hex: string): number[] {
    hex = hex.trim();
    const shortRegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shortRegExp, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  static rgbToHex(rgb: number[]): string {
    return `#${rgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;
  }

  static backgroundSaveHex(color: string): string {
    const rgb = ColorHelper.hex2rgb(color);

    if (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 > 146) {
      return '#202022';
    }

    return '#ffffff';
  }

  static colorRange(colorStart: string, colorEnd: string, colorCount: number): string[] {
    const startRgb = ColorHelper.hex2rgb(colorStart);
    const endRgb = ColorHelper.hex2rgb(colorEnd);
    let alpha = 0.0;
    const saida = [colorEnd];

    for (let i = 0; i < colorCount - 1; i++) {
      alpha += 1 / colorCount;

      const rgb = [
        Math.round(startRgb[0] * alpha + (1 - alpha) * endRgb[0]),
        Math.round(startRgb[1] * alpha + (1 - alpha) * endRgb[1]),
        Math.round(startRgb[2] * alpha + (1 - alpha) * endRgb[2])
      ];

      saida.unshift(ColorHelper.rgbToHex(rgb));
    }

    saida.unshift(colorStart);

    return saida;
  }

  static createRandom(): string {
    return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substring(0, 6);
  }
}
