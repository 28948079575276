import { IBsTreeItem } from '@shared/business-structure/interfaces/bs-tree-item.interface';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { Type } from 'class-transformer';

export class BsNodeItem {
  id: number;
  name: string;
  accounts?: IBsTreeItem[];
  @Type(() => BsNodeItem)
  children?: BsNodeItem[];
  @Type(() => BusinessUnit)
  division?: BusinessUnit;
  isRoot: boolean;
  isNew: boolean;
  isEdit: boolean;
  levelNo: number;

  get showForm(): boolean {
    return this.isNew || this.isEdit;
  }

  get segmentsIterator(): any[] {
    return new Array((this.children?.length || 0) + 1);
  }
}
