import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { IBSDictItem } from '@shared/business-structure/interfaces/bs-dict.interfface';
import { BusinessStructureService } from '@shared/business-structure/services/business-structure.service';
import { businessStructureActions } from '@shared/business-structure/store/business-structure.actions';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import { businessStructureSelectors } from '@shared/business-structure/store/business-structure.selectors';
import { ClientActionsTypes, clientActions } from '@shared/clients/store/client.actions';
import { Observable, of, zip } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class BusinessStructureEffects {
  getBusinessStructure$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(businessStructureActions.getBusinessStructure, clientActions.getBusinessStructureAfterClientSelection),
      switchMap((action) => {
        let call: Observable<any> = of(null);

        if (action.client) {
          call =
            this.__bsFacade.businessStructure && action.client?.slug in this.__bsFacade.businessStructure.data
              ? of(this.__bsFacade.businessStructure.data[action.client.slug])
              : this.__businessStructureService.getBusinessStructureV2(action.client?.slug);
        }

        return zip(of(action.type), of(action.client), call).pipe(
          tap((data) => {
            action.type === ClientActionsTypes.getBusinessStructureAfterClientSelection &&
              this.__businessStructureService.setFirstActiveBu(
                action.client,
                data.length > 2 ? (data[2] as IBSDictItem)?.flattenedDivisions || [] : []
              );
          }),
          switchMap((data) => {
            const getBusinessStructureFinished = businessStructureActions.getBusinessStructureFinished({
              client: data[1],
              tree: data.length > 2 ? (data[2] as IBSDictItem)?.tree || [] : [],
              flattenedDivisions: data.length > 2 ? (data[2] as IBSDictItem)?.flattenedDivisions || [] : []
            });
            const setSelectedClientAfterBusinessStructureFinished =
              businessStructureActions.setSelectedClientAfterBusinessStructureFinished({ client: data[1] });
            return action.type === ClientActionsTypes.getBusinessStructureAfterClientSelection
              ? of(getBusinessStructureFinished, setSelectedClientAfterBusinessStructureFinished)
              : of(getBusinessStructureFinished);
          })
        );
      })
    )
  );

  getBusinessStructureSelection$ = createEffect(() =>
    this.__actions$.pipe(
      ofType(businessStructureActions.getBusinessStructureSelection),
      concatLatestFrom(() => [this.__store.select(businessStructureSelectors.selectBusinessStructureSelection)]),
      switchMap(([action, state]) => {
        if (state.loading !== 'loading') {
          this.__store.dispatch(businessStructureActions.updateBusinessStructureSelectionLoading({ loading: 'loading' }));
          return of(null);
        }
        return zip(of(action.type), of(action.client), this.__businessStructureService.getBusinessStructureV2(action.client?.slug));
      }),
      switchMap((data) => {
        if (data) {
          return of(
            businessStructureActions.getBusinessStructureSelectionFinished({
              client: data[1],
              divisions: data[2].tree
            })
          );
        }

        this.__store.dispatch(businessStructureActions.updateBusinessStructureSelectionLoading({ loading: 'loaded' }));

        return null;
      })
    )
  );

  constructor(
    private readonly __actions$: Actions,
    private readonly __businessStructureService: BusinessStructureService,
    private readonly __store: Store,
    private readonly __bsFacade: BusinessStructureFacade
  ) {}
}
