import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, OnDestroy, ChangeDetectorRef, DestroyRef } from '@angular/core';
import { Account } from '@shared/accounts/models/account.model';
import { BusinessStructureManagerComponent } from '@shared/business-structure/components/business-structure-manager/business-structure-manager.component';
import { BsTreeItemNode } from '@shared/business-structure/models/bs-tree-item-node.model';
import { AccountsFacade } from '@shared/accounts/store/accounts.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bsm-tree-group',
  templateUrl: './bsm-tree-group.component.html',
  styleUrls: ['./bsm-tree-group.component.scss']
})
export class BsmTreeGrpupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() node: BsTreeItemNode;
  protected _children: BsTreeItemNode[] = [];
  protected _isInUserBus = false;

  constructor(
    private __destroyRef: DestroyRef,
    private __accountsFacade: AccountsFacade,
    private __cd: ChangeDetectorRef,
    protected readonly _bsm: BusinessStructureManagerComponent,
    private readonly __elementRef: ElementRef
  ) {}

  @HostBinding('class')
  get hostCssClass(): string {
    const cls: string[] = ['d-flex', 'flex-column'];

    return cls.join(' ');
  }

  @HostBinding('style.--bs-tree-item-level')
  get hostCssVarLevel(): string {
    return (this.node.item.depth || 0).toString();
  }

  ngOnInit(): void {
    if (this.node?.item?.item?.item?.item instanceof Account) {
      const account: Account = this.node?.item?.item?.item?.item;
      this._isInUserBus = this._bsm?.userBus.includes(account.business_unit_id);
    }

    if (this.node.expanded) {
      this._bsm.appendChildren(this.node.treeId, true);
    }

    switch (this._bsm.treeItemsType) {
      case 'account':
        this.__accountsFacade.editAccountsStatus$.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((status) => {
          if (status.status === null) {
            this.node.item.item.selected = false;
            this.__cd.detectChanges();
          }
        });
        break;
    }
  }

  ngOnChanges(): void {
    if (this.node.expanded) {
      this._bsm.appendChildren(this.node.treeId, true);
    }
  }

  ngOnDestroy(): void {
    this.node.expanded = false;
  }

  protected _onCheckboxChange(checked: boolean): void {
    this._bsm.treeMarkAllselected();

    this.__getRecursiveChildren(this.node.item.id).map((item) => {
      item.item.item.selected = checked;
      return item;
    });

    this.__setDragTreeItems();
  }

  private __getRecursiveChildren<T = any>(id: string | number): BsTreeItemNode[] {
    const children: BsTreeItemNode[] = [];
    const childList: BsTreeItemNode[] = this._bsm.getChildren<T>(id);

    for (const child of childList) {
      children.push(child);

      if (child.item.expanded) {
        if (child.item.item.item instanceof Account) {
          children.push(...this.__getRecursiveChildren(child.item.item.item.account_id));
        }
      }
    }

    return children;
  }

  protected _treeToggle(e: MouseEvent, node: BsTreeItemNode): boolean {
    e.preventDefault();
    e.stopPropagation();

    const expansionStorage = sessionStorage.getItem('business-storage-expansion');
    let expansion: { [treeId: string | number]: boolean } = {};

    if (expansionStorage) {
      expansion = JSON.parse(expansionStorage);
    }

    node.expanded = !node.expanded;

    expansion[this.node.treeId] = node.expanded;

    sessionStorage.setItem('business-storage-expansion', JSON.stringify(expansion));
    this._bsm.appendChildren(node.treeId, node.expanded);

    return false;
  }

  private __setDragTreeItems(): void {
    const event: CustomEvent = new CustomEvent('setDragTreeItems', {
      bubbles: true,
      detail: {}
    });

    this.__elementRef.nativeElement.dispatchEvent(event);
  }
}
