<div #trigger matRipple class="d-flex justify-align-start align-items-center" [ngClass]="_isDisabled ? 'disabled' : ''">
  <span class="d-flex justify-align-start align-items-center">
    <span *ngIf="!currentClient"> Choose a client </span>
    <span *ngIf="currentClient"> {{ currentClient.name }} </span>
    <ng-container *ngFor="let bu of path"> <mat-icon>trending_flat</mat-icon> {{ bu.name }} </ng-container>
  </span>
  <mat-icon>keyboard_arrow_down</mat-icon>
  <div class="icon">
    <mat-icon class="outlined">account_tree</mat-icon>
  </div>
</div>

<mat-tree
  #tree
  [ngClass]="{ 'd-flex': showTree, 'd-none': !showTree }"
  class="menu-dropdown-wrapper"
  [dataSource]="dataSource"
  [treeControl]="treeControl"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <span (click)="node.clickable && selectBu(node)" [ngClass]="node.clickable ? 'clickable' : 'not-clickable'">{{ node.item }}</span>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [ngClass]="'node-' + node.entity.id">
    <button mat-icon-button [attr.aria-label]="'Toggle ' + node.item" matTreeNodeToggle [matTreeNodeToggleRecursive]="true">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <span (click)="node.clickable && selectBu(node)" class="root-bu" [ngClass]="node.clickable ? 'clickable' : 'not-clickable'">{{
      node.item
    }}</span>
    <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
  </mat-tree-node>
</mat-tree>
