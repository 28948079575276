<div
  class="tree-item d-flex flex-row justify-content-start align-items-center"
  [ngStyle]="{ '--tree-item-depth': node.depth, 'margin-left': node.depth * 24 + 'px' }"
  [ngClass]="{
    'is-expanded': node.expanded === true,
    'greyed-out':
      node?.item?.item.is_mapped_to_other_client ||
      (node?.item?.item.is_mapped_to_current_client && !_isInUserBus) ||
      node?.item?.item?.is_connected
  }"
  [attr.data-parent-tree-id]="node.parentTreeId || 0"
  [attr.data-tree-id]="node.treeId"
  [attr.data-expanded]="node.expanded === true"
>
  <mat-checkbox
    [disabled]="
      node?.item?.item.is_mapped_to_other_client ||
      (node?.item?.item.is_mapped_to_current_client && !_isInUserBus) ||
      node?.item?.item?.is_connected
    "
    [(ngModel)]="node.item.item.selected"
    (ngModelChange)="_onCheckboxChange($event)"
    color="primary"
  ></mat-checkbox>
  <mat-icon (click)="_treeToggle($event, node)" class="cursor-pointer" [ngClass]="{ 'item-visible': node?.has_children }"
    >expand_more</mat-icon
  >

  <bsm-tree-item
    [node]="node"
    (dragStart)="_bsm.dragTreeStart($event)"
    (dragError)="_bsm.dragTreeError($event)"
    (dragStop)="_bsm.dragTreeStop($event)"
  ></bsm-tree-item>
  <mat-icon
    class="ms-auto cursor-pointer item-visible outlined"
    matTooltip="Edit"
    matTooltipPosition="after"
    (click)="_bsm.editChange.emit(node.item.item)"
    *ngIf="_bsm.editEnabled"
    >edit</mat-icon
  >
</div>
