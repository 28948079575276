import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Account } from '@shared/accounts/models/account.model';
import { BusinessStructureManagerComponent } from '@shared/business-structure/components/business-structure-manager/business-structure-manager.component';
import { BsTreeItemNode } from '@shared/business-structure/models/bs-tree-item-node.model';

@Component({
  selector: 'bsm-tree-item',
  templateUrl: './bsm-tree-item.component.html',
  styleUrls: ['./bsm-tree-item.component.scss']
})
export class BsmTreeItemComponent implements OnInit, OnDestroy {
  protected _isDrag: 'not-really' | 'we-may-have' | 'we-have-a-drag' = 'not-really';
  @Input() node: BsTreeItemNode;
  @Input() parent: BsmTreeItemComponent;
  @ViewChild('inner') private readonly __innerRef: ElementRef;
  @Output() dragStart = new EventEmitter<{ e: MouseEvent; bsmTreeItem: BsmTreeItemComponent }>();
  @Output() dragError = new EventEmitter<{ e: MouseEvent; bsmTreeItem: BsmTreeItemComponent }>();
  @Output() dragStop = new EventEmitter<BsmTreeItemComponent>();
  protected _isInUserBus = false;

  constructor(
    private readonly __hostRef: ElementRef,
    private readonly __bsm: BusinessStructureManagerComponent
  ) {}

  get nativeElement(): HTMLElement {
    return this.__hostRef.nativeElement;
  }

  get innerNativeElement(): HTMLElement {
    return this.__innerRef.nativeElement;
  }

  ngOnInit(): void {
    if (this.node?.item?.item?.item?.item instanceof Account) {
      const account: Account = this.node?.item?.item?.item?.item;
      this._isInUserBus = this.__bsm?.userBus.includes(account.business_unit_id);
    }
  }

  ngOnDestroy(): void {}

  @HostListener('mousedown', ['$event'])
  protected _onHostMousedown(e: MouseEvent): void {
    if (this.node?.item?.item.is_mapped_to_current_client && !this._isInUserBus) {
      return;
    }

    if (e.target === this.__innerRef.nativeElement || this.__innerRef.nativeElement.contains(e.target)) {
      this._isDrag = 'we-may-have';
    }
  }

  @HostListener('document:mousemove', ['$event'])
  protected _onHostMousemove(e: MouseEvent): void {
    if (this._isDrag === 'we-may-have') {
      if (!this.node.item.item.selected) {
        this._isDrag = 'not-really';
        this.dragError.emit({ e, bsmTreeItem: this });
      } else {
        this._isDrag = 'we-have-a-drag';
        this.dragStart.emit({ e, bsmTreeItem: this });
      }
    }
  }

  @HostListener('document:mouseup', ['$event'])
  protected _onDocumentMouseup(e: MouseEvent): void {
    if (this._isDrag !== 'not-really') {
      this._isDrag = 'not-really';
      this.dragStop.emit(this);
    }
  }

  protected _getTooltip(): string {
    const text: string[] = [];

    if (this.node?.item?.item.is_mapped_to_other_client) {
      text.push(`Account is currently mapped to ${this.node.item.item.item.client_name}`);
    }

    if (this.node?.item?.item.is_mapped_to_unaccessible_bu) {
      text.push(`Account is currently mapped to a business unit you don't have access to`);
    }

    if (text.length === 0 && this.node?.item) {
      text.push(`Account ID: ${this.node.item.id}`);
    }

    if (text.length < 2 && text.join(' ').includes('Account ID') && this.node?.item?.item?.is_connected) {
      text.push(`Connected`);
    }

    return text.join('\n');
  }
}
