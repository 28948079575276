import { IBsTreeItem } from '@shared/business-structure/interfaces/bs-tree-item.interface';
import { Type } from 'class-transformer';

export class BsTreeItem<T = any> {
  name: string;
  type: 'account';
  id: string | number;
  parent_id?: string | number;
  depth?: number;
  expanded?: boolean;
  reset_expansion?: boolean;
  item: IBsTreeItem<T>;
  @Type(() => BsTreeItem<T>)
  children?: BsTreeItem<T>[];
}
