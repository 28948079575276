import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { ColorHelper } from '@core/helpers/color.helper';
import { environment } from '@environments/environment';
import { Account } from '@shared/accounts/models/account.model';
import { AccountsFacade } from '@shared/accounts/store/accounts.facade';
import { BusinessStructureManagerComponent } from '@shared/business-structure/components/business-structure-manager/business-structure-manager.component';
import { IBsTreeItem } from '@shared/business-structure/interfaces/bs-tree-item.interface';
import { BsNodeItem } from '@shared/business-structure/models/bs-node-item.model';
import { BsTreeItem } from '@shared/business-structure/models/bs-tree-item.model';
import { BusinessUnitTag } from '@shared/business-structure/models/business-unit-tag.model';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { DialogModalService } from '@shared/dialog/services/dialog.service';
import { GlobalLoaderService } from '@shared/loader/services/global-loader.service';
import { NgxDropdownDirective } from '@shared/ngxdropdown/directives/ngxdropdown';
import { NgxChangeEvent } from '@shared/ngxdropdown/types/ngxchangeevent.type';
import { PopupComponent } from '@shared/popups/components/popup.component';
import { instanceToInstance } from 'class-transformer';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'bsm-node-item',
  templateUrl: './bsm-node-item.component.html',
  styleUrls: ['./bsm-node-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BsmNodeItemComponent implements OnInit {
  private __destroyRef = inject(DestroyRef);

  protected _levels: number[] = [];
  protected _inputControl = new FormControl<string>({
    value: '',
    disabled: false
  });
  private __levelIndex = 0;
  private __mousedownStartTime = 0;
  private __ctrlDown = false;
  private __draggedAccountElement: HTMLElement;
  private __draggedAccounts: IBsTreeItem<Account>[];
  private __draggedDisplayName: string;
  protected _colorHelper = ColorHelper;
  protected _tags: {
    nameControl: FormControl;
    colorControl: FormControl;
    form: string;
    colors: string[];
    matches: BusinessUnitTag[];
  } = {
    nameControl: new FormControl('', { validators: [Validators.required] }),
    colorControl: new FormControl('', { validators: [Validators.required] }),
    form: '',
    colors: environment.businessStructure.tags.colors,
    matches: undefined
  };
  protected _isCollapsed = false;
  protected _isRevealed = false;
  protected _isNodeDrag: 'not-really' | 'we-may-have' | 'we-have-a-drag' = 'not-really';
  protected _isItemDrag: 'not-really' | 'we-may-have' | 'we-have-a-drag' = 'not-really';
  protected _isPlaceholder = false;
  protected __childrenFlat: BsNodeItem[] = [];
  protected __childrenDepth = 0;
  protected _isReadyForEdit = false;
  protected _accountsExpanded = false;
  @Input() willReachAllowedDepth = false;
  @Input() item: BsNodeItem;
  @Input() parent: BsmNodeItemComponent;
  @Input() levelNo: number;
  @Input() isEdit = false;
  @Input() allowedLevelsDepth: number = environment.businessStructure.allowedLevelsDepth;
  @Output() structureChange = new EventEmitter<boolean>();
  @Output() accountsListViewToggle = new EventEmitter<void>();
  @Output() dragStart = new EventEmitter<{ e: MouseEvent; bsmNodeItem: BsmNodeItemComponent }>();
  @Output() dragStop = new EventEmitter<BsmNodeItemComponent>();
  @Output() dragItemStart = new EventEmitter<{ e: MouseEvent; bsmNodeItem: BsmNodeItemComponent }>();
  @Output() dragItemStop = new EventEmitter<BsmNodeItemComponent>();
  @ViewChild('inner') private readonly __innerRef: ElementRef;
  @ViewChild('input') private readonly __inputRef: ElementRef;
  @ViewChild('accountsItem') private readonly __accountsItemRef: ElementRef;

  constructor(
    private readonly __cd: ChangeDetectorRef,
    private readonly __popupService: DialogModalService,
    private readonly __bsm: BusinessStructureManagerComponent,
    private readonly __hostRef: ElementRef,
    private readonly __clientFacade: ClientFacade,
    private readonly __accountsFacade: AccountsFacade,
    private readonly __loaderService: GlobalLoaderService
  ) {}

  get childrenDepth(): number {
    return this.__childrenDepth;
  }

  get availableDepth(): number {
    return this.allowedLevelsDepth - this.levelNo;
  }

  get isMaxDepthAllowed(): boolean {
    return this.levelNo === this.allowedLevelsDepth;
  }

  get nativeElement(): HTMLElement {
    return this.__hostRef.nativeElement;
  }

  get innerNativeElement(): HTMLElement {
    return this.__innerRef.nativeElement;
  }

  get accountsItemNativeElement(): HTMLElement {
    return this.__accountsItemRef.nativeElement;
  }

  get draggedAccountElement(): HTMLElement {
    return this.__draggedAccountElement;
  }

  get draggedAccounts(): IBsTreeItem<any>[] {
    return this.__draggedAccounts;
  }

  get draggedDisplayName(): string {
    return this.__draggedDisplayName;
  }

  @HostBinding('class')
  get hostCssClass(): string {
    const cls: string[] = [];

    if (this.levelNo && this.levelNo > 0) {
      cls.push(`level-${this.levelNo}`);
    }

    if (this.isEdit) {
      cls.push('is-edit');
    }

    if (this.item?.showForm) {
      cls.push('is-item-edit');
    }

    if (this.isFirstInLevel) {
      cls.push('is-first');
    }

    if (this.isLastInLevel) {
      cls.push('is-last');
    }

    if (this._isCollapsed) {
      cls.push('is-collapsed');
    }

    if (this._isRevealed) {
      cls.push('is-revealed');
    }

    if (this._isPlaceholder) {
      cls.push('is-placeholder');
    }

    if (!this.isAccessible) {
      cls.push('is-not-accessible');
    }

    return cls.join(' ');
  }

  get levelIndex(): number {
    return this.__levelIndex;
  }

  get isFirstInLevel(): boolean {
    return this.__levelIndex === 0;
  }

  get isLastInLevel(): boolean {
    return this.__levelIndex === (this.parent?.item?.children?.length || 1) - 1;
  }

  get isPlaceholder(): boolean {
    return this._isPlaceholder;
  }

  get isRoot(): boolean {
    return !!this.item?.isRoot;
  }

  get isAccessible(): boolean {
    return !!this.item?.division?.is_active || (this.isRoot && this.__clientFacade.hasGlobalAccess(this.__clientFacade.selectedClient));
  }

  @Input()
  set placeholder(value: boolean | '') {
    this._isPlaceholder = value === true || value === '';
  }

  protected _onDblClick(): void {
    if (this.isEdit && !this.isRoot && this.isAccessible) {
      this._inputControl.setValue(this.item.name);
      this.item.isEdit = true;
      setTimeout(() => {
        this.__inputRef.nativeElement.focus();
      }, 0);
    }
  }

  @HostListener('document:click', ['$event'])
  protected _onDocumentClick(e: MouseEvent): void {
    if (this.item?.isEdit && e.target !== this.__innerRef.nativeElement && !this.__innerRef.nativeElement.contains(e.target)) {
      this.item.isEdit = false;
    }
  }

  @HostListener('document:keyup', ['$event'])
  protected _onDocumentKeyup(e: KeyboardEvent): void {
    this.__ctrlDown = false;
  }

  @HostListener('document:keydown', ['$event'])
  protected _onDocumentKeydown(e: KeyboardEvent): void {
    this.__ctrlDown = !!e.ctrlKey;
    if (this.item?.showForm) {
      switch (e.key) {
        case 'Escape':
          this._onBlur();
          break;
      }
    } else {
      if (this._isReadyForEdit && e.key === 'Enter') {
        this._onDblClick();
      }
    }
  }

  @HostListener('mousedown', ['$event'])
  protected _onHostMousedown(e: MouseEvent): void {
    if (
      this.isEdit &&
      this.isAccessible &&
      (e.target === this.__innerRef.nativeElement || this.__innerRef.nativeElement.contains(e.target))
    ) {
      this._isNodeDrag = 'we-may-have';
    }
  }

  @HostListener('mousemove', ['$event'])
  protected _onHostMousemove(e: MouseEvent): void {
    const mousedownElapsedTime = Date.now() - this.__mousedownStartTime;
    if (mousedownElapsedTime < 150) {
      return;
    }
    this._isReadyForEdit =
      !this.isRoot &&
      !this.item.isEdit &&
      this.isEdit &&
      this._isNodeDrag === 'not-really' &&
      (e.target === this.__innerRef.nativeElement || this.__innerRef.nativeElement.contains(e.target));

    if (this.isEdit && this._isNodeDrag === 'we-may-have') {
      this._isNodeDrag = 'we-have-a-drag';
      this.dragStart.emit({ e, bsmNodeItem: this });
    } else if (this._isItemDrag === 'we-may-have') {
      this._isItemDrag = 'we-have-a-drag';
      this.dragItemStart.emit({ e, bsmNodeItem: this });
    }
  }

  @HostListener('document:mouseup', ['$event'])
  protected _onDocumentMouseup(e: MouseEvent): void {
    if (this._isNodeDrag !== 'not-really' && this.isEdit) {
      this._isNodeDrag = 'not-really';
      this.dragStop.emit(this);
    } else if (this._isItemDrag !== 'not-really') {
      this._isItemDrag = 'not-really';
      this.dragItemStop.emit(this);
    }
    this.willReachAllowedDepth = false;
  }

  @HostListener('wheel', ['$event'])
  onWheelScroll(e: Event): void {
    if (e.target !== this.__hostRef.nativeElement && !this.__ctrlDown) {
      e.stopPropagation();
    }
  }

  ngOnInit(): void {
    if (this.item) {
      this.item.levelNo = this.levelNo;

      this.refresh();

      if (this.item.isEdit) {
        setTimeout(() => {
          this.__inputRef.nativeElement.focus();
        }, 0);
      }
    }

    this.__bsm.mapping$.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((e) => {
      if (!this.item) {
        return;
      }

      if (this.__bsm.mode === 'mapping' && this.__bsm.treeItemsType === 'account' && !this.__bsm.platform) {
        throw new Error('When mapping accounts you must provide platform slug for business-structure-component!');
      }

      const mapping = e.mapping.filter(
        (item) => (!this.item.division && !item.business_unit_id) || item.business_unit_id === this.item.division?.id
      );
      const items: IBsTreeItem[] = mapping.map((item) => item.item);

      switch (this.__bsm.treeItemsType) {
        case 'account':
          this.item.accounts = items;
          break;
      }

      this.__cd.detectChanges();
    });

    this._tags.nameControl.valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this.__destroyRef)).subscribe((query) => {
      this._tags.matches = undefined;

      if (!query || query === '') {
        return;
      }

      this.__cd.detectChanges();

      const queryLowerCase = query.toLowerCase();
      const tags = this.__bsm.searchTag(query, this.item.division);
      const tagExists = this.item.division.tags?.some((tag) => tag.name.toLowerCase() === queryLowerCase);

      if (tagExists) {
        this._tags.matches = undefined;
      } else {
        this._tags.matches = tags;
      }

      this.__cd.detectChanges();
    });

    this.__bsm.structureChange.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe(() => this.refresh());
    this.__bsm.dragTargetItem.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((e) => {
      this.willReachAllowedDepth = false;
      if (!this.isAccessible) {
        return;
      }

      if (e.target === this && e.source !== e.target) {
        if (e.isTarget) {
          this.nativeElement.classList.add('is-drag-target');
        } else {
          if (e.source instanceof BsmNodeItemComponent && !this.__bsm.treeItemsType) {
            this.willReachAllowedDepth = !this.hasAvailableDepth(e.source);
          }
          this.nativeElement.classList.remove('is-drag-target');
        }
        this.__cd.detectChanges();
      }
    });

    this.__bsm.dragSelectedItem.pipe(takeUntilDestroyed(this.__destroyRef)).subscribe((e) => {
      this.nativeElement.classList.remove('is-drag-target');

      if (!this.isAccessible) {
        return;
      }

      if (e.target === this) {
        // Item is a target of drag

        if (e.dragged instanceof BsmNodeItemComponent) {
          if (e.accounts) {
            // Items added from dragging from another node
            const save: { type: 'account'; items: any[] } = {
              type: null,
              items: []
            };

            if (e.accounts && e.accounts.length) {
              const accounts = this.item.accounts || [];

              save.type = 'account';
              save.items = instanceToInstance(
                e.accounts.filter((account) => !accounts.some((nodeAccount) => nodeAccount.id === account.id)).map((item) => item.item)
              );

              this.item.accounts = [...accounts, ...save.items];
            }

            if (save.items.length) {
              switch (save.type) {
                case 'account':
                  this.__loaderService.dismiss('edit-account');
                  this.__accountsFacade.mappingUpdate(
                    this.__clientFacade.selectedClient,
                    this.__bsm.platform,
                    this.item.division?.id,
                    save.items
                  );
                  break;
              }
            }

            this.structureChange.emit(true);
          } else {
            if (e.dragged.item) {
              if (e.target.item?.children?.includes(e.dragged.item)) {
                // moved to the same parent
                return;
              }

              if (!this.item?.children) {
                this.item.children = [];
              }

              this.item?.children?.push(e.dragged.item);

              this.nativeElement.classList.add('was-dropped');
              this.structureChange.emit(true);

              setTimeout(() => this.nativeElement.classList.remove('was-dropped'), 1000);
            }
          }
        } else if (e.dragged && 'length' in e.dragged && e.dragged.length && e.dragged[0].item instanceof BsTreeItem) {
          // Items added from dragging from tree

          const save: { type: 'account'; items: any[] } = {
            type: null,
            items: []
          };

          for (const item of e.dragged) {
            switch (item.item.type) {
              case 'account':
                if (!this.item.accounts) {
                  this.item.accounts = [];
                }

                if (!save.type) {
                  save.type = 'account';
                }

                if (!this.item.accounts.some((accountItem) => accountItem.id === item.item.item.id)) {
                  const itemClone: IBsTreeItem<Account> = instanceToInstance(item.item.item);
                  this.item.accounts.push(itemClone);

                  save.items.push(itemClone.item);
                }
                break;
            }
          }

          if (save.items.length) {
            switch (save.type) {
              case 'account':
                const mappedAccounts: Account[] = save.items.filter((account: Account) => account.business_unit_id);
                const notMappedAccounts: Account[] = save.items.filter((account: Account) => !account.business_unit_id);

                if (mappedAccounts.length) {
                  this.__accountsFacade.mappingUpdate(
                    this.__clientFacade.selectedClient,
                    this.__bsm.platform,
                    this.item.division?.id,
                    mappedAccounts
                  );
                }

                if (notMappedAccounts.length) {
                  this.__accountsFacade.mappingConnect(
                    this.__clientFacade.selectedClient,
                    this.__bsm.platform,
                    this.item.division?.id,
                    notMappedAccounts
                  );
                }

                break;
            }
          }

          this.structureChange.emit(true);
        }
      } else if (e.dragged === this && this.__bsm.mode === 'manager') {
        // 'this' is element that was tragged

        if (e.dragged.parent?.item === e.target.item) {
          // moved to the same parent
          return;
        }

        !!e.target?.isAccessible && e.dragged.parent?.removeChild(this.item);
      }

      this.refresh();
    });
  }

  removeChild(child: BsNodeItem): void {
    this.item.children?.splice(this.item.children?.indexOf(child), 1);
    this.structureChange.emit(true);
    this.__cd.detectChanges();
  }

  protected _confirmRemoveNode(): void {
    this.__popupService.showConfirm({
      hasBackdrop: true,
      context: {
        title: 'Warning: Removal of Business Unit',
        message: `You are about to remove a business unit or the entire business structure.
          Please note that the selected Business Unit has associations with one or more users, templates, rules, or checks.
          If you proceed with this action, all related entities will be automatically moved to the top-level Business Unit.
          This may impact configurations and permissions within your system.
          Are you sure you want to continue?`,
        confirmText: 'Proceed',
        confirmCb: (popup: PopupComponent): void => {
          popup.hide();
          this.__removeNode(true);
        }
      }
    });
  }

  private __flattenChildren(source: BsNodeItem[], existingDepth = 1): { depth: number; children: BsNodeItem[] } {
    let childrenList: BsNodeItem[] = [];

    let res = source.map((m) => {
      if (m.children && m.children.length) {
        existingDepth++;
        childrenList = [...childrenList, ...m.children];
      }
      return m;
    });
    const subRes = childrenList.length ? this.__flattenChildren(childrenList, existingDepth) : null;

    if (subRes) {
      existingDepth = subRes.depth;
      res = res.concat(subRes.children);
    }

    return { depth: existingDepth, children: res };
  }

  refresh(): void {
    this.__refreshDepth();
    this.__refreshIndex();

    this.__cd.detectChanges();
  }

  private __refreshDepth(): void {
    const levels: number[] = [];
    let levelNo = this.levelNo || 0;
    const res = this.item?.children ? this.__flattenChildren(this.item.children) : null;
    this.__childrenDepth = res && res.children.length ? res.depth : 0;
    this.__childrenFlat = res ? res.children : [];

    if (this.__childrenDepth > 0) {
      for (let i = 0; i < this.__childrenDepth; i++) {
        levels.push(++levelNo);
      }
    }

    this._levels = levels;
  }

  private __refreshIndex(): void {
    this.__levelIndex = this.parent && this.parent?.item?.children ? this.parent?.item?.children.findIndex((i) => i === this.item) : 0;
  }

  private __removeNode(change = true): void {
    if (this.parent?.item) {
      this.structureChange.emit(change);

      setTimeout(() => {
        this.parent.removeChild(this.item);
        this.__cd.markForCheck();
      });
    }
  }

  protected _addNode(): void {
    if (!this.item.children) {
      this.item.children = [];
    }

    const item = new BsNodeItem();
    item.isNew = true;
    item.isEdit = true;

    item.division = new BusinessUnit();
    item.division.is_active = true;

    this.item.children.push(item);

    this.refresh();

    this.__cd.detectChanges();
  }
  protected _moveNode(direction: 'left' | 'right'): void {
    const curItemIndex = this.parent.item.children.findIndex((el) => el.name === this.item.name);
    const neighborIndex = direction === 'left' ? curItemIndex - 1 : curItemIndex + 1;
    this.parent.item.children[curItemIndex] = [
      this.parent.item.children[neighborIndex],
      (this.parent.item.children[neighborIndex] = this.parent.item.children[curItemIndex])
    ][0];
    this.structureChange.emit(true);
    this.refresh();
    this.__cd.detectChanges();
  }
  protected _onBlur(): void {
    if (this.isEdit) {
      if (this.item.isNew) {
        if (!this._inputControl.value) {
          this.__removeNode(false);
        } else {
          this._onUpdate();
          this.structureChange.emit(true);
        }
      } else {
        if (!this._inputControl.value) {
          this.item.isEdit = false;
        }
      }
    }
  }

  protected _onUpdate(): void {
    if (this._inputControl.pristine) {
      return;
    }

    const wasChange = !!this._inputControl.value && this._inputControl.value.toLowerCase() !== (this.item.name || '').toLowerCase();
    const names: string[] = (wasChange ? this._inputControl.value : this.item.name).split(',').map((i) => i.trim());
    const itemName = names.shift();

    this.item.name = itemName;
    this.item.isNew = false;
    this.item.isEdit = false;

    this.item.division.name = itemName;

    const items: BsNodeItem[] = [];

    for (const name of names) {
      if (name === '') {
        continue;
      }

      const item = new BsNodeItem();
      item.name = name;

      item.division = new BusinessUnit();
      item.division.name = name;
      item.division.is_active = true;

      items.push(item);
    }

    if (this.parent) {
      if (this.isFirstInLevel) {
        this.parent?.item?.children.unshift(...items);
      } else {
        this.parent?.item?.children.splice(this.__levelIndex + 1, 0, ...items);
      }
    }

    this._inputControl.markAsPristine();
    this.refresh();
    this.structureChange.emit(wasChange);
  }

  collapse(): void {
    this.__hostRef.nativeElement.parentNode.classList.add('collapsed');
    this._isCollapsed = true;
  }

  expand(): void {
    this.__hostRef.nativeElement.parentNode.classList.remove('collapsed');
    this._isCollapsed = false;
  }

  unreveal(): void {
    if (this._isCollapsed && this._isRevealed) {
      this.__hostRef.nativeElement.parentNode.classList.remove('reveal');
      this._isRevealed = false;
    }
  }

  reveal(): void {
    if (this._isCollapsed && !this._isRevealed) {
      this.__hostRef.nativeElement.parentNode.classList.add('reveal');
      this._isRevealed = true;
    }
  }

  hasChild(item: BsmNodeItemComponent | BsNodeItem): boolean {
    if (item instanceof BsmNodeItemComponent) {
      item = item.item;
    }
    return this.__childrenFlat && this.__childrenFlat.includes(item);
  }

  isParent(item: BsmNodeItemComponent | BsNodeItem): boolean {
    if (item instanceof BsmNodeItemComponent) {
      item = item.parent.item;
    }

    return this.item === item;
  }

  hasAvailableDepth(item: BsmNodeItemComponent): boolean {
    return this.availableDepth > item.childrenDepth;
  }

  onTagsModalChange(e: NgxChangeEvent): void {
    if (!!e.afterClose) {
      this._tagFormCancel();
    }
  }

  _openTags(event: MouseEvent, modal: NgxDropdownDirective): void {
    modal.show(event.target as HTMLElement);
  }

  _tagFormSubmit(): void {
    if (!this.item.division.tags) {
      this.item.division.tags = [];
    }

    const tag = new BusinessUnitTag();
    tag.name = this._tags.nameControl.value;
    tag.color = this._tags.colorControl.value;

    this.item.division.tags.push(tag);

    this.structureChange.emit(true);

    this._tagFormCancel();
  }

  _tagAssign(tag: BusinessUnitTag): void {
    if (!this.item.division.tags) {
      this.item.division.tags = [];
    }

    const newTag = instanceToInstance(tag);

    delete newTag.id;

    this.item.division.tags.push(newTag);

    this.structureChange.emit(true);

    this._tagFormCancel();
  }

  _tagEditStart(tag: BusinessUnitTag, target: HTMLInputElement): void {
    tag.editName = tag.name;
    tag.isEdit = true;
    target.setSelectionRange(tag.name.length + 1, tag.name.length + 1);
    target.focus();
  }

  _tagEditCancel(tag: BusinessUnitTag, e?: KeyboardEvent | HTMLInputElement): void {
    if (e instanceof KeyboardEvent) {
      if (e.key === 'Escape') {
        tag.isEdit = false;

        const target = e.target as HTMLInputElement;
        target.setSelectionRange(0, 0);
        target.focus();
      }
    } else {
      tag.isEdit = false;
      e.setSelectionRange(0, 0);
      e.focus();
    }
  }

  _tagEditSave(tag: BusinessUnitTag, target: HTMLInputElement): void {
    tag.name = tag.editName;
    tag.isEdit = false;
    target.setSelectionRange(0, 0);
    target.focus();
  }

  _tagDelete(tag: BusinessUnitTag): void {
    this.__popupService.showConfirm({
      hasBackdrop: true,
      context: {
        title: 'Remove this label?',
        message: 'Are you sure you want to delete this label?',
        confirmText: 'Delete',
        confirmCb: (popup: PopupComponent): void => {
          popup.hide();

          const index = this.item.division.tags.indexOf(tag);

          if (index > -1) {
            this.item.division.tags.splice(index, 1);

            this.structureChange.emit(true);
          }
        }
      }
    });
  }

  _tagFormCancel(): void {
    this._tags.form = '';
    this._tags.matches = undefined;
    this._tags.nameControl.setValue('');
    this._tags.nameControl.markAsPristine();
    this._tags.nameControl.updateValueAndValidity();
    this._tags.colorControl.setValue('');
    this._tags.colorControl.markAsPristine();
    this._tags.colorControl.updateValueAndValidity();
  }

  protected _onAccountClick(e: MouseEvent, account: IBsTreeItem<Account>): void {
    this.__draggedAccountElement = e.target as HTMLElement;
    this.__draggedAccounts = account ? [account] : this.item.accounts;
    this.__draggedDisplayName = account ? account.name : `${this.item.accounts.length} Account${this.item.accounts.length > 1 ? 's' : ''}`;
    this.__mousedownStartTime = Date.now();
    this._isItemDrag = 'we-may-have';
    this.accountsListViewToggle.emit();
  }
}
