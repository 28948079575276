<div class="line-segments d-flex justify-content-stretch" *ngIf="levelNo > 1">
  <div></div>
  <div></div>
</div>
<form
  class="box no-drag d-flex justify-content-stretch align-items-stretch"
  (submit)="_onUpdate()"
  [ngClass]="{ disabled: willReachAllowedDepth }"
>
  <div class="button top d-flex justify-content-center" *ngIf="levelNo > 1 && isAccessible">
    <button
      type="button"
      class="icon"
      mat-raised-button
      color="primary"
      mat-icon-only
      (click)="_confirmRemoveNode()"
      matTooltip="Remove unit"
      matTooltipPosition="after"
    >
      <mat-icon class="outline">delete</mat-icon>
    </button>
  </div>
  <mat-icon
    *ngIf="item?.division && (item.division?.tags?.length || (isEdit && isAccessible))"
    class="tags-button outlined"
    (click)="_openTags($event, tagsModal)"
    >sell</mat-icon
  >
  <div
    class="inner d-flex flex-column justify-content-center align-items-center flex-grow-1 py-16 px-24"
    #inner
    (dblclick)="_onDblClick()"
    data-cy="new-unit-input"
  >
    <span class="label text-center">{{ item?.name }}</span>
    <input class="input py-8 text-center" type="text" [formControl]="_inputControl" name="name" (blur)="_onBlur()" #input />
    <div
      class="items d-flex flex-column align-items-center mt-12"
      [ngClass]="{ 'is-dragged': _isItemDrag === 'we-have-a-drag', 'd-none': !item?.accounts?.length }"
    >
      <ng-container *ngIf="item?.accounts?.length">
        <div
          class="items__chip accounts px-16 py-8"
          #accountsItem
          (click)="_accountsExpanded = !_accountsExpanded"
          (mousedown)="_onAccountClick($event, null)"
        >
          <span>{{ item?.accounts?.length | numbersFormat }} Account<ng-container *ngIf="item?.accounts?.length > 1">s</ng-container></span>
          <!-- <mat-icon class="ms-4">expand_more</mat-icon> -->
        </div>
        <div class="all-accounts-separator my-12" [ngClass]="_accountsExpanded ? 'visible' : 'invisible'"></div>
        <div class="all-accounts" [ngClass]="_accountsExpanded ? 'visible' : 'invisible'">
          <div
            (mousedown)="_onAccountClick($event, account)"
            *ngFor="let account of item?.accounts"
            class="items__chip items__chip--individual accounts px-16 py-8 mt-8"
          >
            {{ account.name }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="button bottom d-flex justify-content-center">
    <button
      type="button"
      class="icon add-node me-auto"
      mat-raised-button
      color="primary"
      mat-icon-only
      matTooltip="Move left"
      matTooltipPosition="before"
      (click)="_moveNode('left')"
      [disabled]="isFirstInLevel"
      *ngIf="isEdit && isAccessible"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <ng-container *ngIf="levelNo < allowedLevelsDepth">
      <button
        type="button"
        class="icon add-node"
        mat-raised-button
        color="primary"
        mat-icon-only
        matTooltip="Add unit"
        matTooltipPosition="below"
        (click)="_addNode()"
        *ngIf="isEdit && isAccessible"
      >
        <mat-icon>add</mat-icon>
      </button>
      <ng-container *ngIf="!isEdit">
        <button
          type="button"
          class="icon collapse"
          mat-raised-button
          color="primary"
          mat-icon-only
          (click)="collapse()"
          matTooltip="Hide unit tree"
          matTooltipPosition="after"
          *ngIf="item?.children && item?.children.length && !_isCollapsed"
        >
          <mat-icon>close_fullscreen</mat-icon>
        </button>
        <button
          type="button"
          class="icon expand"
          mat-raised-button
          color="primary"
          mat-like-icon-only
          (click)="expand()"
          matTooltip="Show unit tree"
          matTooltipPosition="after"
          *ngIf="item?.children && item?.children.length && _isCollapsed"
        >
          <span class="reveal-dot" *ngFor="let level of _levels" [ngClass]="['level-' + level]"></span>
        </button>
      </ng-container>
    </ng-container>
    <button
      type="button"
      class="icon add-node ms-auto"
      mat-raised-button
      color="primary"
      mat-icon-only
      matTooltip="Move right"
      matTooltipPosition="after"
      (click)="_moveNode('right')"
      [disabled]="isLastInLevel"
      *ngIf="isEdit && isAccessible"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</form>
<div
  class="line-down d-flex justify-content-stretch"
  *ngIf="item?.children?.length && levelNo < allowedLevelsDepth"
  [ngClass]="{ higher: item?.children?.length === 1 }"
>
  <div></div>
  <div></div>
</div>

<div
  class="tags-modal rounded-4"
  ngxdropdown
  #tagsModal="ngxdropdown"
  [ngxdropdownoptions]="{ position: 'bottomright', showOverlay: false, hideOnOverlayClick: true }"
  (ngxChange)="onTagsModalChange($event)"
  [ngClass]="{ form: _tags.form !== '' }"
>
  <form (submit)="_tagFormSubmit()" class="inner d-flex flex-column justify-content-start align-items-stretch p-16">
    <ng-container *ngIf="isEdit">
      <div class="d-flex align-items-center mt-4 mb-8">
        <h4 class="m-0">Add label</h4>
        <span class="flex-spacer"></span>
        <ng-container *ngIf="_tags.form !== ''">
          <button type="button" mat-button mat-smaller-button class="color-blue" (click)="_tagFormCancel()">Cancel</button>
          <button
            type="submit"
            mat-button
            mat-smaller-button
            class="color-blue"
            [disabled]="_tags.nameControl.invalid || _tags.colorControl.invalid"
          >
            Add
          </button>
        </ng-container>
      </div>
      <div class="input rounded-4 bg-color-neutral-variant--100">
        <mat-icon>search</mat-icon>
        <input type="text" [formControl]="_tags.nameControl" required />
        <div class="color" [ngStyle]="{ '--color': _tags.colorControl.value }"></div>
      </div>
      <button
        type="button"
        class="mt-4"
        mat-button
        mat-small-button
        *ngIf="_tags.nameControl.value !== '' && _tags.matches !== undefined && !_tags.matches.length && _tags.form === ''"
        (click)="_tags.form = _tags.nameControl.value"
      >
        <mat-icon class="color-primary--500">add</mat-icon>
        Create new label
      </button>
      <div
        *ngIf="_tags.form !== ''"
        class="colors rounded-4 bg-color-neutral-variant--100 d-flex flex-wrap justify-content-start mt-8 pt-8 ps-4"
      >
        <div
          *ngFor="let color of _tags.colors"
          class="color mb-4"
          [ngStyle]="{ '--color': color }"
          [ngClass]="{ selected: _tags.colorControl.value === color }"
          (click)="_tags.colorControl.setValue(color)"
        >
          <mat-icon class="color-white">check</mat-icon>
        </div>
      </div>
      <ng-container *ngIf="_tags.matches?.length">
        <h4 class="mt-12 mb-8">Search results</h4>
        <div class="tags-list pb-4 d-flex flex-wrap justify-content-start">
          <ng-container *ngFor="let tag of _tags.matches; let i = index">
            <div
              class="rounded-pill d-flex align-items-center"
              [ngStyle]="{ '--color': tag.color, '--color-rgb': _colorHelper.hex2rgb(tag.color) }"
            >
              <mat-icon (click)="_tagAssign(tag)">add</mat-icon>
              <span>{{ tag.name }}</span>
            </div>
            <div class="flex-spacer" *ngIf="(i + 1) % 2 === 0"></div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item?.division?.tags?.length">
      <h4 class="mt-12 mb-8" *ngIf="isEdit">Applied</h4>
      <div class="tags-list pb-4 d-flex flex-wrap justify-content-start">
        <ng-container *ngFor="let tag of item.division?.tags; let i = index">
          <form
            (submit)="isEdit && _tagEditSave(tag, input)"
            class="rounded-pill d-flex justify-content-start align-items-center"
            [ngClass]="{ 'mt-12': !isEdit }"
            [ngStyle]="{ '--color': tag.color || '', '--color-rgb': tag.color ? _colorHelper.hex2rgb(tag.color) : '' }"
          >
            <mat-icon (click)="_tagDelete(tag)" *ngIf="isEdit">close</mat-icon>
            <input
              #input
              type="text"
              name="tag-{{ i }}"
              [ngClass]="{ 'is-edit': !!tag.isEdit }"
              [(ngModel)]="tag.formName"
              [readonly]="!tag.isEdit"
              [size]="tag.formName.length > 2 ? tag.formName.length - 2 : 1"
              (dblclick)="isEdit && _tagEditStart(tag, input)"
              (blur)="isEdit && _tagEditCancel(tag, input)"
              (keyup)="isEdit && _tagEditCancel(tag, $event)"
            />
          </form>
          <div class="flex-spacer" *ngIf="(i + 1) % 2 === 0"></div>
        </ng-container>
      </div>
    </ng-container>
  </form>
</div>
