import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '@environments/routes/api-routes';
import { BusinessUnitPayload } from '@shared/business-structure/models/business-unit-payload.model';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import { ClientBuBranchRoot } from '@shared/clients/models/client-bu-branch-root.model';
import { Client } from '@shared/clients/models/client.model';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable()
export class BusinessStructureService {
  constructor(
    private readonly __httpClient: HttpClient,
    private readonly __clientFacade: ClientFacade
  ) {}

  getBusinessStructureV2(slug: string): Observable<{ tree: BusinessUnit[]; flattenedDivisions: BusinessUnit[] }> {
    return this.__httpClient.get<BusinessUnit[]>(API_ROUTES.businessStructure.tree(slug)).pipe(
      map((r) => {
        const flattenedDivisions: BusinessUnit[] = [];
        r.forEach((division) => this.__flattenDivisions(division, flattenedDivisions));
        return { tree: plainToInstance(BusinessUnit, r), flattenedDivisions: plainToInstance(BusinessUnit, flattenedDivisions) };
      })
    );
  }

  saveBusinessStructureV2(data: BusinessUnitPayload[]): Observable<{ tree: BusinessUnit[]; flattenedDivisions: BusinessUnit[] }> {
    const client = this.__clientFacade.selectedClient;
    return this.__httpClient.put<BusinessUnit[]>(API_ROUTES.businessStructure.tree(client.slug), data).pipe(
      map((r) => {
        const flattenedDivisions: BusinessUnit[] = [];
        r.forEach((division) => this.__flattenDivisions(division, flattenedDivisions));
        return { tree: plainToInstance(BusinessUnit, r), flattenedDivisions: plainToInstance(BusinessUnit, flattenedDivisions) };
      })
    );
  }

  getBusinessUnitBranch(slug: string, businessUnitOrId: BusinessUnit | number): Observable<ClientBuBranchRoot> {
    if (businessUnitOrId instanceof BusinessUnit) {
      businessUnitOrId = businessUnitOrId.id;
    }
    return this.__httpClient
      .get<ClientBuBranchRoot>(API_ROUTES.businessStructure.businessUnitBranch(slug, businessUnitOrId))
      .pipe(map((r) => plainToInstance(ClientBuBranchRoot, r)));
  }

  setFirstActiveBu(client: Client, flattenedDivisions: BusinessUnit[]): void {
    const currentBu = this.__clientFacade.selectedBusinessUnit;
    const hasAccessToGlobal = currentBu === null && this.__clientFacade.hasGlobalAccess(client);
    const hasAccessToBu = currentBu && flattenedDivisions.find((bu) => bu.id === currentBu.id)?.is_active;

    if (hasAccessToGlobal || hasAccessToBu) {
      return;
    }
    const firstActiveBu = this.__clientFacade.hasGlobalAccess(client) ? null : flattenedDivisions.find((bu: BusinessUnit) => bu?.is_active);
    this.__clientFacade.setSelectedBusinessUnit(firstActiveBu || null);
  }

  private __flattenDivisions(node: BusinessUnit, flattenedDivisions: BusinessUnit[]): void {
    flattenedDivisions.push(node);
    if (node.divisions) {
      node.divisions.forEach((division) => this.__flattenDivisions(division, flattenedDivisions));
    }
  }
}
