import { BusinessUnitBranch } from '@shared/business-structure/models/business-unit-branch.model';
import { ClientModule } from '@shared/clients/models/client.model';
import { Type } from 'class-transformer';

export class ClientBuBranchRoot {
  id: number;
  slug: string;
  name: string;
  logo_url: string;
  color: string;
  modules: ClientModule[];
  @Type(() => BusinessUnitBranch)
  business_units: BusinessUnitBranch[];
}
