import { createReducer, on } from '@ngrx/store';
import { IBSDict } from '@shared/business-structure/interfaces/bs-dict.interfface';
import { BusinessUnit } from '@shared/business-structure/models/business-unit.model';
import {
  BusinessStructureNafigationFinishedProps,
  BusinessStructureSelectionFinishedProps,
  BusinessStructureSelectionLoadingProps,
  businessStructureActions
} from '@shared/business-structure/store/business-structure.actions';
import { StoreStateRequestPart } from '@shared/ngrx/models/store-state-request-part.model';
import {
  storePersistentStateLoadedReducer,
  storePersistentStateLoadingReducer
} from '@shared/ngrx/reducers/store-persistent-state.reducer';

export class BusinessStructureGeneralState extends StoreStateRequestPart {
  data?: IBSDict;
}

export class BusinessStructureSelectionState extends StoreStateRequestPart {
  dict?: { [clientSlug: string]: BusinessUnit[] };
}

export interface BusinessStructureState {
  businessStructureGeneral: BusinessStructureGeneralState;
  businessStructureSelection: BusinessStructureSelectionState;
}

const initialBusinessStructureState: BusinessStructureState = {
  businessStructureGeneral: new BusinessStructureGeneralState(),
  businessStructureSelection: new BusinessStructureSelectionState()
};

export const businessStructureReducer = createReducer(
  initialBusinessStructureState,

  on(businessStructureActions.getBusinessStructure, storePersistentStateLoadingReducer<BusinessStructureState>('businessStructureGeneral')),
  on(
    businessStructureActions.getBusinessStructureFinished,
    businessStructureActions.updateBusinessStructure,
    storePersistentStateLoadedReducer<BusinessStructureState, BusinessStructureGeneralState, BusinessStructureNafigationFinishedProps>(
      'businessStructureGeneral',
      (state, props) => {
        if (!state.error && props.client) {
          if (!state.data) {
            state.data = {};
          }
          state.data[props.client.slug] = { tree: props.tree, flattenedDivisions: props.flattenedDivisions };
        }

        return state;
      }
    )
  ),
  on(
    businessStructureActions.getBusinessStructureSelection,
    storePersistentStateLoadingReducer<BusinessStructureState>('businessStructureSelection')
  ),
  on(
    businessStructureActions.getBusinessStructureSelectionFinished,
    storePersistentStateLoadedReducer<BusinessStructureState, BusinessStructureSelectionState, BusinessStructureSelectionFinishedProps>(
      'businessStructureSelection',
      (state, props) => {
        if (!state.error && props.client) {
          if (!state.dict) {
            state.dict = {};
          }
          state.dict[props.client.slug] = props.divisions;
        }

        return state;
      }
    )
  ),
  on(
    businessStructureActions.updateBusinessStructureSelectionLoading,
    storePersistentStateLoadedReducer<BusinessStructureState, BusinessStructureSelectionState, BusinessStructureSelectionLoadingProps>(
      'businessStructureSelection',
      (state, props) => {
        state.loading = props.loading;
        return state;
      }
    )
  )
);
