<div class="inner" #inner>
  <div class="name">
    <span
      class="me-12"
      [matTooltip]="_getTooltip()"
      matTooltipPosition="after"
      [ngClass]="
        node?.item?.item.is_mapped_to_other_client || (node?.item?.item.is_mapped_to_current_client && !_isInUserBus) ? 'greyed' : ''
      "
      >{{ node?.item?.name }}
    </span>
  </div>
</div>
